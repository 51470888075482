<template>
  <validation-observer
    v-if="status"
    ref="formWebhook"
    autocomplete="off"
    tag="form"
  >
    <dialog-form
      :status="status"
      :is-update="false"
      :width="800"
      title="Webhook"
      @action="fetchAction"
      @close="$emit('close-dialog')"
    >
      <v-row>
        <v-col
          cols="12"
          md="12"
        >
          <validation-provider
            v-slot="{ errors }"
            name="Nome"
            rules="required"
            vid="name"
          >
            <v-text-field
              v-model="name"
              :error-messages="errors"
              :hide-details="errors.length === 0"
              label="Nome"
              outlined
              dense
            />
          </validation-provider>
        </v-col>

        <v-col
          cols="12"
          md="4"
          sm="6"
        >
          <validation-provider
            v-slot="{ errors }"
            name="Method"
            rules="required"
            vid="method"
          >
            <v-select
              v-model="method"
              :error-messages="errors"
              :hide-details="errors.length === 0"
              :items="['GET', 'POST', 'PUT', 'PATCH', 'DELETE']"
              label="Method"
              outlined
              dense
            />
          </validation-provider>
        </v-col>

        <v-col
          cols="12"
          md="8"
          sm="6"
        >
          <validation-provider
            v-slot="{ errors }"
            name="URL"
            rules="required|url"
            vid="url"
          >
            <v-text-field
              v-model="url"
              :error-messages="errors"
              :hide-details="errors.length === 0"
              label="URL"
              outlined
              dense
            />
          </validation-provider>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="12"
          md="12"
        >
          <CodeEditor
            ref="jsonBody"
            :value="jsonBody"
            :line-nums="true"
            :languages="[['json', 'JSON']]"
            font-size="14px"
            height="200px"
            width="100%"
            theme="atom-one-dark"
          />
        </v-col>
      </v-row>
    </dialog-form>
  </validation-observer>
</template>

<script>
import CodeEditor from 'simple-code-editor'
import { mapActions } from 'vuex'

export default {
  components: {
    CodeEditor,
  },

  props: {
    status: {
      default: true,
      type: Boolean,
    },
    jsonBody: {
      default: '{\n  "phone": "${phone}",\n  "digit": "${digit}"\n}',
      type: String,
    },
  },

  data() {
    return {
      name: '',
      method: 'GET',
      url: '',
    }
  },

  methods: {
    ...mapActions('webhook', ['create', 'find']),

    formatJson(body) {
      try {
        JSON.parse(body)
      } catch (e) {
        this.$notify('JSON inválido', 'error')
      }
    },

    async fetchAction() {
      const json = this.$refs.jsonBody.$el.querySelector('textarea').value.trim()
      this.formatJson(json)

      if (await this.$refs.formWebhook.validate()) {
        await this.create({
          name: this.name,
          method: this.method,
          url: this.url,
          body: JSON.parse(this.jsonBody),
        })

        this.$emit('close-dialog')
        this.$emit('find')
      }
    },
  },
}
</script>

<style>
.code-editor .code-area > textarea {
  color: #34ac48 !important; /* Dark text color */
}
</style>
