<template>
  <validation-observer
    ref="formSmsTemplate"
    autocomplete="off"
    tag="form"
  >
    <dialog-form
      :status="status"
      :is-update="false"
      :width="1200"
      title="SMS Template"
      @action="create"
      @close="$emit('close-dialog')"
    >
      <v-row>
        <v-col
          cols="6"
          sm="12"
          md="6"
        >
          <v-row>
            <v-col
              cols="12"
              md="6"
              sm="12"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Nome"
                rules="required|max:100"
                vid="form.name"
              >
                <v-text-field
                  v-model="name"
                  :error-messages="errors"
                  :hide-details="errors.length === 0"
                  label="Nome"
                  outlined
                  dense
                />
              </validation-provider>
            </v-col>
            <v-col
              cols="12"
              md="6"
              sm="12"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Tipo"
                rules="required"
                vid="type"
              >
                <v-select
                  v-model="type"
                  :items="smsTemplateTypes"
                  :error-messages="errors"
                  :hide-details="errors.length === 0"
                  outlined
                  item-text="text"
                  dense
                  item-value="value"
                  label="Tipo"
                />
              </validation-provider>
            </v-col>
            <v-col
              cols="12"
              sm="12"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Mensagem"
                rules="required"
                vid="form.message"
              >
                <v-textarea
                  v-model="message"
                  :counter-value="(value) => getMessageLenth(value)"
                  :error-messages="errors"
                  counter
                  label="Mensagem"
                  outlined
                >
                </v-textarea>
              </validation-provider>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="6"
          class="d-sm-flex justify-center position-relative"
        >
          <div class="phone">
            <div class="speaker"></div>
            <div style="width: 425px; height: 771px">
              <div class="center">
                <div class="chat">
                  <div
                    id="chat"
                    class="messages"
                  >
                    <div
                      v-if="chat"
                      class="message parker"
                    >
                      <!-- eslint-disable-next-line -->
                      <span v-html="chat" />
                    </div>
                  </div>
                  <div class="input">
                    <input
                      placeholder="Enviar mensagem..."
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </dialog-form>
  </validation-observer>
</template>

<script>
import { mapActions } from 'vuex'
import { smsTemplateTypes } from '@/utils/enum'

export default {
  props: {
    status: {
      default: true,
      type: Boolean,
    },
  },

  data() {
    return {
      chat: '',
      name: '',
      message: '',
      type: null,
      smsTemplateTypes,
    }
  },

  watch: {
    message(value) {
      const text = value.replace(/(?:\r\n|\r|\n)/g, '<br>')
      this.chat = this.$utils.replaceSpecialCharacters(text)
    },
  },

  methods: {
    ...mapActions('smsTemplates', {
      createTemplate: 'create',
    }),

    getMessageLenth(value) {
      return String(value.replace(/(?:\r\n|\r|\n)/g, '\n\n')).length
    },

    async create() {
      const body = {
        name: this.name,
        type: this.type,
        message: this.$utils.replaceSpecialCharacters(this.message),
      }

      if (this.getMessageLenth(body.message) > 160) {
        this.$notify(
          'O campo mensagem pode conter até 160 caracteres.',
          'error',
        )

        return
      }

      if (await this.$refs.formSmsTemplate.validate()) {
        await this.createTemplate(body)

        this.chat = ''
        this.name = ''
        this.message = ''
        this.type = null

        this.$emit('close-dialog')
        this.$emit('find')
      }
    },
  },
}
</script>
