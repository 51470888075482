export function getTotalPhones(phones) {
  return phones.split('\n')
    .map(row => row.replace(/\D/g, ''))
    .filter(row => !!row)
}

export function groupByArrayAndRemoveDuplicates(phones) {
  return Object.keys(phones.reduce((acc, currentValue) => {
    acc[currentValue] = true

    return acc
  }, {}))
}

export function fileToCsv(str) {
  const delimiter = str.indexOf(';') !== -1 ? ';' : ','
  const headers = str.slice(0, str.indexOf('\n')).split(delimiter)

  headers.forEach((header, i) => {
    headers[i] = String(header.replace(/\r/g, '').replace(/\n/g, '').trim()).toUpperCase()
  })

  const rows = str.slice(str.indexOf('\n') + 1).split('\n')

  rows.forEach((row, i) => {
    rows[i] = row.replace(/\r/g, '').replace(/\n/g, '').trim()
  })

  return rows.map(row => {
    const values = row.split(delimiter)

    return headers.reduce((object, header, i) => {
      // eslint-disable-next-line no-param-reassign
      object[header] = values[i]

      return object
    }, {})
  }).filter(row => [undefined, null, ''].includes(row['NUMERO']) === false) // eslint-disable-line
}

export function getPhonesFromCsv(csv) {
  return csv.reduce((acc, currentValue) => {
    if (acc.find(row => row['NUMERO'] === currentValue['NUMERO']) === undefined) { // eslint-disable-line
      acc.push(currentValue)
    }

    return acc
  }, [])
}
